import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PostBlocks from '../components/PostBlocks'
import { graphql } from 'gatsby'

const HaqPage = ({ data }) => {
  const entry = data.craftGql.haq[0]

  return (
    <Layout>
      <SEO
        title={entry.title}
        description={entry.metaDescription}
        image={entry.metaImage[0]}
      />
      <article className={`max-w-md mx-auto mb-32`}>
        <h1
          className={`text-5xl font-bold mt-12 mb-6 leading-none px-6 md:px-0`}
        >
          {entry.title}
        </h1>
        <PostBlocks blocks={entry.postBlocks} />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    craftGql {
      haq: entries(section: "hypotheticallyAskedQuestions") {
        title
        ... on CraftGQL_hypotheticallyAskedQuestions_hypotheticallyAskedQuestions_Entry {
          metaDescription
          metaImage {
            url
          }
          postBlocks {
            ...PostBlocks
          }
        }
      }
    }
  }
`

export default HaqPage
